
// announce
// 24 Aug, 21
import React from "react"
import { useStaticQuery, graphql } from "gatsby"


const Annouce = () => {
    return (
      <section className="announce">
        <div className="container">
          <div className="announce-wrapper producthunt">
          </div>
        </div>
      </section>

    )
}

export default Annouce
